@import 'variables';
@import 'breakpoints';

.online-users {

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-top: 5rem;
  padding-bottom: 1rem;
  flex-wrap: wrap;

  @include md {
    width: 250px;
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0;
    // justify-content: space-between;
  }
  // justify-content: center;

  // p {
  //   text-align: center;
  //   margin-bottom: 1rem;
  // }

  .online-user-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .online-user-photo {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
    }

    .online-user-indicator {
      position: absolute;
      width: 12px;
      height: 12px;
      background-color: $completed;
      border-radius: 50%;
      right: 0;
      bottom: 22px;
    }
  }

  
}