$navHeight: 8vh;
$navColor: white;
$mobileSidebar: rgb(212, 212, 212);
$primary: #4285F4;
$light: rgb(120, 124, 126);
$loading: black;
$completed: #49be25;
$inwork: #6c25be;
$marketing: #d47839;
$development: #6a74ff;
$design: #5dd1d9;
$detail: rgb(78, 78, 78);
