@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&family=Open+Sans:wght@300;400;500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
}

.container {
  max-width: 90%;
  margin: 0 auto;
}

.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}