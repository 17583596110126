@import 'variables';
@import 'breakpoints';

.form {
  form {
    margin: 8rem auto;
    max-width: 300px;
    h2 {
      text-align: center;
    }
    // display: flex;
    // flex-direction: column;
    // align-items: center;

    label {
      display: block;
      margin: 1rem 0;
      span {
        display: block;
        margin-bottom: .5rem;
      }

      input, textarea {
        width: 100%;
        line-height: 1rem;
        padding: .5rem;
        font-size: 1rem;
        border-radius: 4px;
        border: 1px solid $light;
        outline: none;

        &:focus {
          border: 1px solid $primary;
        }
      }
      textarea {
        min-height: 160px;
      }
    }

    button {
      display: block;
      margin: 0 auto;
      color: white;
      background-color: $primary;
      border: 1px solid $primary;
      border-radius: 4px;
      font-size: 1rem;
      padding: .5em 1em;
      cursor: pointer;
      // text-align: center;
      // align-self: center;
    }

    .redirect {
      margin-top: 3rem;
      text-align: center;
      line-height: 1.5;

      a {
        text-decoration: underline;

        &:hover {
          color: $primary;
        }
      }
    }

    .test {
      margin-top: 3rem;
      text-align: center;
      line-height: 1.5;
    }

  }

  @include md {
    form {
      max-width: 400px;
    }
  }
}