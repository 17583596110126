@import 'variables';

.notifications-detail {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  // align-items: center;
  // justify-content: center;
  a {
    // padding: 1rem 0;
    // box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

    span {
      display: block;
    }
  
  
    span:nth-of-type(1) {
      color: $detail;
    }
  
    span:nth-of-type(2) {
      color: $light;
      font-size: .875rem;
      // margin-left: 3rem;
    }
  }

  

  
}

.notifications-delete-btn {
  display: block;
  margin: 0 auto;
  color: white;
  background-color: red;
  border: 1px solid red;
  border-radius: 4px;
  font-size: 1rem;
  padding: .5em 1em;
  cursor: pointer;
  margin-top: 3rem;
  // text-align: right;
}