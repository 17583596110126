@import 'variables';
@import 'mixins';
@import 'breakpoints';

nav {
  width: 100%;
  height: $navHeight;
  position: fixed;
  z-index: 3;
  background-color: $navColor;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  .navbar {
    position: relative;
    @include display-center;
    height: 100%;

    .logo {
      height: 100%;
      width: 3rem;
      @include display-center;
      a {
        width: 100%;
        @include display-center;
        // height: 100%;
      }
    }

    .desktop-user {
      display: none;
    }

    .desktop-links {
      display: none;
      // gap: 2rem;
    }
    
    .menu {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 2rem;
      @include display-center;
    }

    .profile {
      width: 50px;
      height: 50px;
      // background-color: yellow;
      border-radius: 50%;
      overflow: hidden;

      // img {
      //   width: 100%;
      //   height: 100%;
      // }
    }
    
    .mobile-sidebar {
      position: fixed;
      right: 0;
      top: $navHeight;
      // background-color: $mobileSidebar;
      background-color: white;
      width: 200px;
      height: calc(100% - $navHeight);
      overflow-y: scroll;
      padding-top: 3rem;
      transition: all ease-in-out 0.2s;
      // box-shadow: rgba(50, 50, 93, 0.25) 2px 0px 5px -1px, rgba(0, 0, 0, 0.3) 1px 0px 3px -1px;
      box-shadow: -1px 0px 0 1px rgba(50, 50, 93, 0.25);
      box-shadow: rgba(50, 50, 93, 0.25) -2px 0px 5px -1px, rgba(0, 0, 0, 0.3) -1px 0px 3px -1px;


      a {
        display: block;
        padding: .75rem 1rem;
        display: flex;
        align-items: center;

        img {
          margin-right: .5rem;
        }

        &.active {
          border-left: 3px solid $primary;
          // color: $primary;
        }
      }

      .create  {
        background-color: $primary;
        color: white;
        margin: 0 1.5rem 2rem 1.5rem;
        padding: .5rem;
        // margin-bottom: 1rem;
        border-radius: 10rem;
        justify-content: center;
      }

      p {
        margin: 0 0 1rem .5rem;
      }


      .mobile-sidebar-user {
        // margin: 3rem 0;
        padding: 0 1rem;
        display: flex;
        gap: 2rem;

        // flex-direction: column;
        align-items: center;
        margin-bottom: 2rem;

        a {
          padding: 0;
          position: relative;

          span {
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: red;
            top: 0;
            right: 8px;
          }
        }
      }
  
      .mobile-sidebar-categories {
        margin-top: 3rem;

      }
  
      .mobile-sidebar-logout {
        margin-top: 3rem;
        padding: 0 1rem;
        display: flex;
        align-items: center;
  
        img {
          margin-right: .5rem;
        }
      }
  
      .mobile-links {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
        // padding-left: 1rem;
        color: $primary;
        // display: ;
      }

    }
  
    .disable-mobile-sidebar {
      transform: translateX(100%);
      transition: all ease-in-out 0.2s;
    }

    .links {
      display: flex;
      gap: 2rem;
    }
  }
  
  @include md {
    .navbar {
      justify-content: space-between;

      .menu {
        display: none;
      }

      .desktop-links {
        display: flex;
        gap: 2rem;
        // color: $primary;
        font-weight: 500;
        font-size: 1.125rem;
      }
  
      .mobile-sidebar {
        left: 0;
        overflow-y: auto;
        box-shadow: rgba(50, 50, 93, 0.25) 2px 0px 5px -1px, rgba(0, 0, 0, 0.3) 1px 0px 3px -1px;

        a.active {
          border-left: none;
          border-right: 3px solid $primary;
        }
  
        .mobile-sidebar-user {
          display: none;
        }
  
        .mobile-sidebar-logout {
          display: none;
        }

        .mobile-notification {
          display: none;
        }

        .mobile-links {
          display: none;
        }
      }
  
      .disable-mobile-sidebar {
        transform: translateX(0);
      }

      .desktop-user {
        display: flex;
        gap: 2rem;
        // text-align: center;

        a {
          position: relative;

          span {
            position: absolute;
            width: 8px;
            height: 8px;
            background-color: red;
            border-radius: 50%;
            top: 25%;
            right: 0;
          }
        }

        & > * {
          display: flex;
          align-items: center;
        }

        .desktop-logout {
          cursor: pointer;
        }
        // .desktop-logout {
        // }
      }
    }

    
  }


}


